
import { defineComponent } from "vue";
import InputSelect from "../../../../components/inputs/inputSelect/InputSelect.vue";
import InputSwitch from "../../../../components/inputs/inputSwitch/InputSwitch.vue";
import { useSettingsStore } from "../../../../store/settings";
import { storeToRefs } from "pinia";

export default defineComponent({
    name: "settingsGeneral",
    components: { InputSelect, InputSwitch },
    setup() {
        const { selectedLanguage, hasBiggerNavigation, testLanguages } =
            storeToRefs(useSettingsStore());

        function saveNavbarSize(): void {
            localStorage.setItem(
                "hasBiggerNavigation",
                JSON.stringify(hasBiggerNavigation.value)
            );
        }
        return {
            selectedLanguage,
            hasBiggerNavigation,
            testLanguages,
            saveNavbarSize,
        };
    },
});
