import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-2 pl-8 w-full" }
const _hoisted_2 = { class: "mt-6" }
const _hoisted_3 = { class: "md:flex justify-between" }
const _hoisted_4 = { class: "mt-2 text-grey-darkest" }
const _hoisted_5 = { class: "w-64 md:mt-0 mt-3" }
const _hoisted_6 = { class: "md:flex justify-between mt-5" }
const _hoisted_7 = { class: "mt-2 text-grey-darkest" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_select = _resolveComponent("input-select")!
  const _component_input_switch = _resolveComponent("input-switch")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h6", null, _toDisplayString(_ctx.$t("general")), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("settings.choose_language")), 1),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_input_select, {
            id: "select-language",
            name: "select-language",
            label: 'Sprache',
            isMultiSelect: false,
            options: _ctx.testLanguages,
            selectItemConfiguration: {},
            selectItemStyle: {},
            isDisabled: true,
            isInputDisabled: true,
            hasSearch: false,
            modelValue: _ctx.selectedLanguage,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedLanguage) = $event))
          }, null, 8, ["options", "modelValue"])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("settings.bigger_navigation")), 1),
        _createVNode(_component_input_switch, {
          class: "md:mt-0 mt-3",
          id: 'switch-bigger-nav',
          name: 'switch-bigger-nav',
          modelValue: _ctx.hasBiggerNavigation,
          "onUpdate:modelValue": [
            _cache[1] || (_cache[1] = ($event: any) => ((_ctx.hasBiggerNavigation) = $event)),
            _cache[2] || (_cache[2] = ($event: any) => (_ctx.saveNavbarSize()))
          ],
          label: '',
          tabIndex: 1
        }, null, 8, ["modelValue"])
      ])
    ])
  ]))
}